.logo {
	width: 96px;
}

.stripes {
	width: 208px;
	display: block;
}

.userButton {
	--button-height: 40px;
	height: var(--button-height);
	background: transparent;
	border-radius: calc(var(--button-height) / 2);
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: var(--space-inline-xs);
	border: 0;
	padding: 0 var(--space-inline-xs) 0 0;
	.userInitialsBubble {
		flex-shrink: 0;
		width: var(--button-height);
		height: var(--button-height);
		border-radius: calc(var(--button-height) / 2);
		background: #ece7ee;
		border: 1px solid var(--border-color);
		font-size: large;
		font-weight: 700;
		letter-spacing: 1px;
		text-transform: uppercase;
		padding-top: calc(var(--button-height) / 5);
	}
	.userName {
		translate: 0 -1px;
	}
	.buttonIcon.buttonIcon {
		flex-shrink: 0;
		min-width: 10px;
		font-size: 14px;
		translate: -3px 0;
	}
}
.userButton:hover,
.userButtonOpen {
	background: #f2f4f3;
	.userInitialsBubble {
		border: 1px solid #003824;
	}
}
.userButton:hover {
	.userInitialsBubble {
		background: #ece7ee;
	}
}
.userButtonOpen {
	.userInitialsBubble {
		background: #f2f4f3;
	}
}

.actionsMenu {
	box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1019607843);
	border-radius: var(--space-inset-2xs);
	overflow: hidden;
	z-index: 10;
}
.actionsMenuItem {
	cursor: pointer;
	display: flex;
	width: 300px;
	height: 56px;
	align-items: center;
	gap: var(--space-inline-sm);
	padding: 0 var(--space-inset-md);
	background: var(--background-color);
}
.actionsMenuItem:not(:last-child) {
	border-bottom: 1px solid var(--line-color);
}
.actionsMenuItem[data-highlighted] {
	background: var(--selected-color);
	outline: 0;
}
