.link {
	color: var(--action-color);
	text-decoration: none;
	border-bottom: 1px solid transparent;
	width: auto;
	cursor: pointer;
}

.internalLink,
.externalLink,
.buttonLink,
.backButtonLink {
	font-weight: 700;
}

.icon {
	margin-left: var(--space-inline-2xs);
	color: var(--text-dark);
	vertical-align: text-bottom;
	font-size: 20px;
}

.small {
	font-size: var(--font-size-body-sm);
	font-weight: 400;
}

.internalLink::after {
	content: '\0203A';
	padding-left: var(--space-inline-2xs);
}

.internalLink:hover,
.externalLink:hover,
.internalLink:active,
.externalLink:active {
	color: var(--action-color);
	border-bottom: 1px solid var(--highlight-color);
	transition: all 0.15s ease-in-out;
}

.buttonLink:hover > .text,
.buttonLink:active > .text {
	color: var(--action-color);
	border-bottom: 2px solid var(--highlight-color);
	transition: all 0.15s ease-in-out;
}

.backButtonLink:hover .text {
	color: var(--text-dark);
}

.buttonLink,
.backButtonLink {
	display: flex;
	color: var(--text-dark);
}

.buttonLink:hover .buttonIcon {
	color: var(--action-color);
	transition: all 0.15s ease-in-out;
}

.text {
	border-bottom: 2px solid transparent;
}

.buttonIcon {
	display: flex;
	align-self: center;
	justify-content: center;
	margin-right: var(--space-inline-2xs);
	color: var(--text-dark);
}

.paragraph {
	text-decoration: underline;
	color: var(--text-dark);
}

.paragraph:hover {
	color: var(--action-color);
	border-bottom: 0;
	transition: all 0.15s ease-in-out;
}

.paragraph::after {
	content: '';
	padding-left: 0;
}
