.drawer {
	border: 1px solid var(--border-color);
}

.iframeWrapper {
	height: 100%;
}
.iframeWrapper iframe {
	height: 100%;
	width: 100%;
}

.drawerContent {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.drawerHeader {
	display: flex;
	padding: var(--space-inline-lg);
	justify-content: space-between;
}

.drawerFooter {
	padding: var(--space-inline-lg);
	display: flex;
	align-items: center;
}
