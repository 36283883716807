.container {
	height: 100vh;
}

.tenantOption {
	position: relative;
	cursor: pointer;
	display: flex;
	width: 100%;
	border-radius: var(--space-inset-xs);
	/* Highlights all columns inside tenant option */
}
.tenantOption .tenantOption > * {
	min-height: 80px;
	max-height: 80px;
}
.tenantOption:hover .optionColumn,
.tenantOption:focus .optionColumn {
	border-color: var(--border-hovered-color);
}
.tenantOption .infoColumn {
	padding: 0 var(--space-inset-md);
	border-bottom-left-radius: var(--space-inset-xs);
	border-top-left-radius: var(--space-inset-xs);
	border: 1px solid var(--border-color);
	flex: 1;
	text-decoration: inherit;
	color: inherit;
	height: 80px;
}
.tenantOption .infoColumn:hover,
.tenantOption .infoColumn:focus {
	background: var(--selected-color);
}
.tenantOption .infoColumn:hover .tenantLabel,
.tenantOption .infoColumn:focus .tenantLabel {
	background: var(--bg-beige-full);
}
.tenantOption .infoColumn > div {
	height: 100%;
}
.tenantOption .infoColumn .identifierHint {
	color: var(--read-only);
	font-size: var(--font-size-body-sm);
	line-height: normal;
	white-space: nowrap;
}
.tenantOption .infoColumn .orgNumber {
	line-height: normal;
	white-space: nowrap;
}
.tenantOption .infoColumn .infoIcon {
	position: relative;
}
.tenantOption .infoColumn .infoIcon .infoCircle {
	position: absolute;
	top: -1px;
	right: 0;
	background-color: var(--accent-orange);
	min-width: 13px;
	height: 13px;
	border-radius: 9999px;
	font-size: 10.5px;
	line-height: 13px;
	color: white;
	text-align: center;
	padding-left: 3px;
	padding-right: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tenantOption .infoColumn .infoIcon .infoLoading {
	animation: infoLoadingAnimation 1s ease-in-out infinite alternate;
}
@keyframes infoLoadingAnimation {
	0% {
		opacity: 0.2;
	}
	100% {
		opacity: 0.5;
	}
}
.tenantOption .header .systemAdminTooltipContainer {
	padding: 0;
	padding-inline-end: 0;
	padding-inline-start: 0;
}
.tenantOption .header .systemAdminTooltipContainer .crown {
	height: 18px;
	font-size: 18px;
	min-height: 18px;
	min-width: auto;
}
.tenantOption button.devAlertIconButton {
	padding: 0;
	margin: 0;
}
.tenantOption .dropdownTrigger {
	border-top-right-radius: var(--space-inset-xs);
	border-bottom-right-radius: var(--space-inset-xs);
	border: 1px solid var(--border-color);
	border-left: 0;
}
.tenantOption .dropdownTrigger:hover,
.tenantOption .dropdownTrigger :focus,
.tenantOption .dropdownTrigger[data-state='open'] {
	background: var(--selected-color);
}
.tenantOption .dropdownTrigger .triggerIcon {
	display: flex;
	height: 100%;
	align-items: center;
	width: 55px;
}
.tenantOption .asideIcons {
	position: absolute;
	left: -10px;
	display: flex;
	height: 100%;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}
.tenantOption .asideIcons > * {
	background-color: var(--bg-dark-green-full);
	border-radius: 16px;
	width: 24px;
	height: 24px;
	font-size: 16px;
}
.tenantOption .asideIcons > *.pinned {
	pointer-events: none;
	position: absolute;
	transform: rotate(-16.125deg);
	left: 0;
	top: -3px;
}

.chevronRightIcon {
	font-size: 18px !important;
	color: var(--text-dark);
}

.removeTenantOptionButton.removeTenantOptionButton.removeTenantOptionButton.removeTenantOptionButton {
	border-color: pink;
	color: pink;
}
.removeTenantOptionButton.removeTenantOptionButton.removeTenantOptionButton.removeTenantOptionButton:hover,
.removeTenantOptionButton.removeTenantOptionButton.removeTenantOptionButton.removeTenantOptionButton:focus {
	border-color: deeppink;
	color: deeppink;
}

.hideIfEmpty:empty {
	display: none;
}

.tenantSelectDialog {
	width: 700px;
}

.overrideSideMenu.overrideSideMenu.overrideSideMenu {
	height: auto;
}

.actionsMenu {
	box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1019607843);
	border-radius: var(--space-inset-2xs);
	overflow: hidden;
	z-index: 10;
}

.actionsMenuItem {
	cursor: pointer;
	display: flex;
	width: 300px;
	height: 56px;
	align-items: center;
	gap: var(--space-inline-sm);
	padding: 0 var(--space-inset-md);
	background: var(--background-color);
}
.actionsMenuItem:not(:last-child) {
	border-bottom: 1px solid var(--line-color);
}
.actionsMenuItem[data-highlighted] {
	background: var(--selected-color);
	outline: 0;
}

.icon14px {
	min-width: 14px !important;
	min-height: 14px !important;
}
.icon14px svg {
	width: 14px;
	height: 14px;
}

.iconInText {
	vertical-align: bottom;
	min-width: 12px !important;
}

.togglingLink,
.togglingLink:hover {
	cursor: pointer;
	color: var(--action-color);
	text-decoration: none;
	border-bottom: 1px transparent;
}
.togglingLink:hover {
	border-bottom: 1px solid var(--highlight-color);
}

.togglingIcon.togglingIcon.togglingIcon {
	font-size: small;
	translate: 0 1px;
	min-width: auto;
	min-height: auto;
	margin-left: 4px;
	margin-right: 4px;
}

.feedbackIconButton:not(.disabled) {
	/* The AwesomeIconButton component has opacity set to 0.8. In this case we want to keep the opacity at 1. */
	opacity: 1;

	width: 32px;
	height: 32px;
	min-width: 32px;
	min-height: 32px;
	border-radius: 50%;
	background-color: color-mix(in srgb, var(--action-color) 8%, transparent);
	border: 1px solid color-mix(in srgb, var(--action-color) 40%, transparent);

	/* Transition used by the general eternal-smooth-ui Button component. Copied to keep behavior consistent with the FeedbackButton component */
	transition:
		color 0.3s ease-in-out,
		background-color 0.3s ease-in-out,
		border-color 0.3s ease-in-out,
		box-shadow 0.3s ease-in-out;

	/* Hover used by eternal-smooth-ui FeedbackButton. Copied to keep behavior consistent with the FeedbackButton component */
	&:hover {
		background-color: color-mix(in srgb, var(--action-color) 15%, transparent);
	}
}

.heading {
	display: inline;
	padding-inline-end: var(--space-inset-lg);
}
