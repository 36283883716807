.container {
	height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.subContainer {
	flex-grow: 1;
	flex-basis: 50%;
}

.imageContainer {
	background-position-x: center;
	background-position-y: top;
	background-repeat: no-repeat;
	background-size: cover;
	position: sticky;
	top: 0;

	/* fallback for browsers without image-set support */
	background-image: url('../images/Fortnox-onboarding-start-1.jpg');
	background-image: image-set(
		url('../images/Fortnox-onboarding-start-1.avif') type('image/avif'),
		url('../images/Fortnox-onboarding-start-1.jpg') type('image/jpeg')
	);
}
