.chip {
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	padding: 2px 8px;
	gap: 10px;
	white-space: nowrap;

	/* Background/BG Beige Light */

	background: #f8f1e8;
	border-radius: 28px;

	/* Inside auto layout */

	flex: none;
	flex-grow: 0;
}

.variant-error {
	background: var(--bg-red-med);
}

.size-large {
	padding: 4px 12px;
}
