@import '@fnox/eternal-smooth-ui/src/style/fonts.css';
@import '@fnox/eternal-smooth-ui/dist/style.css';

#root {
	height: 100vh;
	overflow: auto;
	position: relative;
}

.max-w-640 {
	max-width: 640px;
}

.truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* button color is missing from eternal-smooth-ui css reset. This causes the browsers default button color to ber used,
   which is blue on Safari iOS and black on other browsers */
button {
	color: var(--text-dark);
}
