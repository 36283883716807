.container {
	display: flex;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	align-items: stretch;
	overflow: auto;
}

@media only screen and (max-width: 1200px) {
	.container {
		flex-direction: column-reverse;
	}

	.image {
		min-height: 200px;
		max-height: 300px;
		background-position-y: 40%;
	}
}

.content {
	flex: 1;
	flex-basis: 0;
	display: flex;
	align-items: center;
}

.image {
	flex: 1;
	flex-basis: 0;
	background-size: cover;
	background-position-x: 70%;
}
